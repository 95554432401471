.lt-input-placeholder
  color: $color-text-100

.lt-input-focus
  outline: 0
  border-color: $color-outline-250

.lt-input-disabled
  background-color: $color-text-50
  border-color: $color-text-50

.lt-input-invalid
  background-color: $color-primary-negative-50
  border-color: $color-primary-negative-50

.lt-input-text
  @include secondary-font-regular()
  @include input-field-base()

  width: 100%
  background-color: $color-outline-50

  &::placeholder
    @extend .lt-input-placeholder

  &:focus
    @extend .lt-input-focus

  &:disabled
    @extend .lt-input-disabled

  &.lt-input-text--invalid,
  &:invalid
    @extend .lt-input-invalid

.lt-input-text__errors
  color: $color-primary-negative-200
  display: flex
  flex-direction: column
  margin-bottom: 8px

.lt-textarea
  @extend .lt-input-text

  resize: none

  &::placeholder
    @extend .lt-input-placeholder

  &:focus
    @extend .lt-input-focus

  &:disabled
    @extend .lt-input-disabled

  &.lt-textarea--invalid,
  &:invalid
    @extend .lt-input-invalid

.lt-label
  color: $color-text-200
  line-height: normal
  margin-bottom: 5px

  &.lt-label--required
    &:after
      @include primary-font-bold()

      content: "*"
      display: inline-block
      color: $color-brand-primary-200

.lt-input-error,
.lt-textarea-error
  margin-top: 6px
  margin-bottom: 6px
  color: $color-primary-negative-200
  font-size: $font-size-regular

.lt-dropdown-root
  width: 100%
  position: relative

  &:after
    content: ''
    position: absolute
    top: 50%
    right: 20px
    width:  0
    height: 0
    margin-top: 7px
    border: 9px solid
    border-color: $color-text-200 transparent transparent transparent
    transform: translateY(-50%)

  &.is-open
    .lt-dropdown-control
      border-bottom-right-radius: 0
      border-bottom-left-radius:  0

    .dropdown-arrow
      transform: rotateZ(180deg)

.lt-dropdown-control
  @include primary-font-medium()
  @include input-field-base()

  display: flex
  align-items: center
  justify-content: space-between
  background-color: $color-outline-50

.lt-dropdown-arrow
  width: 10px
  height: 6px
  background-repeat: no-repeat


.lt-dropdown-menu
  position: absolute
  z-index: 10
  left:  0
  right: 0
  background-color: $color-outline-50
  border: 1px solid $color-outline-100
  border-top: none
  border-bottom: none
  border-bottom-right-radius: 3px
  border-bottom-left-radius:  3px
  box-shadow: $shadow-level-1
  max-height: 220px
  overflow-y: scroll

.lt-dropdown-option
  // Make the option into a flexbox to move checkmark to end of row
  display: flex
  align-items: center
  justify-content: space-between

  position: relative // To enable z-index
  @include primary-font-medium()
  @include hover-transition-simple()

  padding: 15px
  border-bottom: 1px solid $color-outline-100
  color: $color-text-200

  &.is-selected
    position: relative
    color: $color-brand-primary-200
    background-color: #fff
    box-shadow: $shadow-level-2
    z-index: 100

    // Checkmark
    &:after
      content: ""
      display: block
      width: 5px
      height: 10px
      border: solid $color-brand-primary-200
      border-width: 0 3px 3px 0
      -webkit-transform: rotate(45deg)
      -ms-transform: rotate(45deg)
      transform: rotate(45deg)

  &:hover
    color: $color-brand-primary-200
    background-color: #fff
    box-shadow: $shadow-level-3
    z-index: 101 // shadow should be above selected item's shadow

.fieldset
  border: none
  padding: 0
  margin: 0 0 $spacing-base 0

.label
  @include secondary-font-bold($font-size-large)
  display: block
  color: $color-text-300
  margin-bottom: 8px
