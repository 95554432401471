@import "~style/vars.sass"
@import "~style/mixins.sass"

.user-management__list 
  list-style: none
  padding: 0 16px


.user-management__list-item 
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid #DDE4EA
  color: #677C8A
  padding: 15px 0

  &.user-management__list-item--loader 
    width: 100%
    height: 22px
  

  &:last-child 
    border-bottom: none
  
  @media #{$tablet, $phone} 
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    padding: 15px 0
    line-height: 1.15


.user-management__list-row 
  flex: 1 0 210px
  
  &.user-management__list-row--admin-text 
    flex: 1 0 50px

    @media #{$tablet, $phone} 
      flex-basis: auto
      margin-bottom: 10px
    
      

  &.user-management__list-row--actions 
    flex: 1 0 100px
    display: flex
    align-items: center
    justify-content: flex-end

  @media #{$tablet, $phone} 
    flex-basis: auto
    padding: 3px 0

    &.user-management__list-row--actions 
      justify-content: flex-start
      flex-basis: auto
    

.icon-button--edit 
  box-sizing: border-box
  display: block
  background-color: transparent
  background-image: url('./icon-edit.svg')
  background-repeat: no-repeat
  background-position: center center

.icon-button 
  border: 1px solid #D8D8D8
  border-radius: 50%
  width: 40px
  height: 40px
  display: flex
  cursor: pointer

  &.icon-button--left 
    margin-right: 16px
  

  &:hover 
    border-color: #64a60a
  

  &:active 
    background-color: #64a60a
  

