
@import "~style/mixins.sass"
@import "~style/vars.sass"

.context-navigation
  background-color: $white
  @include shadow-level-2-top-inset()

  @media #{phone, $tablet}
    box-shadow: none

.context-navigation__content
  width: 267px

  @media #{phone, $tablet}
    margin: 0 auto
    width: 90%

.context-navigation__list
  list-style-type: none
  margin: 0
  padding: 50px 0 0 0

  @media #{phone, $tablet}
    padding: 0

.context-navigation__list-item
  display: block

  @media #{phone, $tablet}
    display: inline-block
    margin: 0 9px
    padding-left: 0

.context-navigation__list-item:first-child
  @media #{phone, $tablet}
    margin-left: 0

.context-navigation__list-item:last-child
  @media #{phone, $tablet}
    margin-right: 0

.context-navigation__navlink
  padding: 19px 0 19px 35px
  display: block

  @media #{phone, $tablet}
    padding-left: 0

.context-navigation__navlink--active
  @include secondary-font-bold()
  color: $color-brand-primary-200
  border-left: 3px solid $color-brand-primary-200
  padding-left: 30px

  @media #{phone, $tablet}
    border-left: 0 none
    border-bottom: 3px solid $color-brand-primary-200
    padding: 19px 0 16px 0
