.loading-spinner
  display: inline-block
  content: ""
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.03)
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%)
  background-repeat: no-repeat
  background-size: 800px 104px
  display: inline-block
  animation-duration: 1.5s
  animation-fill-mode: forwards
  animation-iteration-count: infinite
  animation-name: shimmer
  animation-timing-function: linear

  &--line
    height: 60px

@keyframes shimmer
  0%
    background-position: -468px 0
  100%
    background-position:  468px 0