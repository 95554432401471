@import "~style/vars.sass"
@import "~style/mixins.sass"

.user-inputs__title
  @include primary-font-regular()
  margin-bottom: 12px

.user-inputs__container
  display: flex
  flex-direction: column
  row-gap: 8px