@import "~style/vars.sass"
@import "~style/mixins.sass"

.pagination 
  width: 100%
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center
  margin-top: 16px

.pagination__page-container 
  float: right
  height: 38px
  background-color: $white
  margin: 0 0px
  align-items: center
  display: flex

  @media #{$phone}
    margin: 0 0px

.pagination__page 
  @include primary-font-regular($font-size-regular)
  @include hover-transition-simple()

  display: flex
  justify-content: center
  align-items: center
  float: left
  width: 40px
  height: 100%
  text-align: center
  cursor: default
  font-size: 16px
  vertical-align: middle
  
  &.page-input
    background-color: #ebebeb
    border: 0
    border-radius: 10px
    color: #2e2e2e
    font-weight: 600
    height: 32px

    &:focus
      outline: 0
  
  &.direction
    background-position: center
    background-repeat: no-repeat
    background-color: white
    border: none
    cursor: pointer

    &:hover
      background-color: #ebebeb

  &.direction--prev
    background-image: url("~images/chevron-left.static.svg")

  &.direction--next
    background-image: url("~images/chevron-right.static.svg")

  &.total-pages,
  &.current-page,
  &.page-input
    @media #{$phone}
      display: none

  @media #{$phone} 
    width: 30px
    font-size: $font-size-small