@import "~style/vars.sass"
@import "~style/mixins.sass"

.warning
  background-color: #FFF9CC
  padding: 18px
  margin-bottom: 24px
  font-family: $font-barlow
  line-height: 24px
  & > div:first-of-type
    display: flex
    flex-wrap: wrap
    flex-direction: row
    gap: 10px
    align-content: center
  ul
    margin-left: 22px
    margin-top: 5px
