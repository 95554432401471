@import "~style/vars.sass"
// Rules for overriding React day-picker style

.DayPickerInput-Overlay
  width: 100%

.DayPicker
  width: 100%

.DayPicker-wrapper
  padding-bottom: 0

.DayPicker-Month
  width: 100%
  margin: 0

.DayPicker-WeekDays
  margin: 0 10px

.DayPicker-Weekday:first-child
  border-right: solid 1px $lt-grey-line

.DayPicker-Body
  margin: 0 10px

.DayPicker-Caption
  color: $color-text-300
  font-family: $font-lt-bold
  text-align: center
  height: 48px
  margin-bottom: 0px
  border-bottom: 1px solid $lt-grey-line
  div
    margin: 13px 0

.DayPicker-NavButton
  top: .4rem
  right: .6rem
  width: 1.8rem
  height: 1.8rem
  border: 1px solid $gainsboro-gray
  border-radius: 2rem
  background-size: 25%

.DayPicker-NavButton--prev
  position: absolute
  left: 8px
  background-image: url("~images/chevron-left.static.svg")

.DayPicker-NavButton--next
  background-image: url("~images/chevron-right.static.svg")

.DayPicker-Day
  height: 40px
  width: 36px
  padding: 0
  box-sizing: border-box
  border-radius: 0px
  font-family: $font-open-sans
  color: $lt-green
  font-size: 12px
  border: 1px solid $light-gray-2

  &:hover
    background-color: $alice-blue


.DayPicker-Day--disabled
  color: $disabled-gray

  &:hover
    background-color: transparent