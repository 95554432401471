
@import "~style/vars.sass"
@import "~style/mixins.sass"

.documents--desktop
  width: 100%
  background-color: $white
  padding: $spacing-base
  border-spacing: 0

.document
  &--mobile
    padding: $spacing-small
    background-color: $white
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.05)
    border-radius: 4px
    margin-bottom: $spacing-small
  &--desktop
    height: 72px
  &--desktop-no-document
    height: 0


.document__header
  font-family: $font-open-sans-bold
  font-weight: bold

  &--mobile
    font-size: 12px
    margin: 0

  &--desktop
    padding-bottom: 12px
    font-size: 14px
    color: $color-brand-primary-200
    text-align: left

  &--download-desktop
    width: 40px

  &--desktop-date
    width: 200px

  &--desktop-name
    width: 30%

  &--sort
    cursor: pointer

.document__value
  font-size: $font-size-micro

  &--mobile
    font-family: $font-open-sans
    margin: 0 0 $spacing-small

  &--desktop
    border-top: 1px solid $color-outline-100
    vertical-align: middle

  &--desktop-no-document
    vertical-align: middle
    padding-bottom: 0.3rem
    padding-top: 1.3rem

  &--desktop-bold
    font-family: $font-open-sans-bold
    font-weight: bold
    color: $dark-text

  &:last-child
    margin: 0

.document__sort-asc
  transform: rotate(-180deg)

.documents--sub-title
  font-family: $font-lt-regular
  font-weight: 400
  font-size: $font-size-title-small
  color: $black
  margin-top: $spacing-base
  margin-bottom: $spacing-small