@import "~style/vars.sass"
@import "~style/mixins.sass"

.selection-box
  width: 100%
  display: flex
  flex-wrap: wrap

.selection-box__item
  @include hover-transition-simple()
  @include secondary-font-bold($font-size-small)
  display: flex
  justify-content: center
  align-items: center
  width: 50%
  padding: 20px 10px
  text-align: center
  color: $color-text-300
  background-color: $color-outline-50
  border: 1px solid $color-outline-200

  &:hover
    background-color: $color-brand-primary-250
    color: $white

  &--selected
    background-color: $color-brand-primary-200
    color: $white

  &--small
    width: 33%
