@import "~style/vars.sass"
@import "~style/mixins.sass"

.login-page__container
  display: flex
  background-color: $white
  align-items: stretch
  min-height: 900px
  position: relative
  height: 100%

  @media #{$tablet}
    display: block
    min-height: auto
    height: auto

  footer.footer
    @media #{$tablet}
      position: relative

.login-page__content
  flex: 1 1 50%

.login-page__content--left
  display: flex
  justify-content: center
  align-items: center

.login-page__content--right
  background-color: #d0c5b5
  background-image: url("./raksanappi-login-hand.png")
  background-repeat: no-repeat
  background-size: contain
  background-position: center bottom

  @media #{$tablet}
    display: none

.login-page__content--right-image
  position: absolute

.login-page__title
  @include primary-font-bold($font-size-title-regular)
  margin: 0 0 48px 0
  color: #1A1A1A

.login-page__logo
  height: 32px
  margin-bottom: 80px
  align-self: flex-start

.login-page__input
  @include primary-font-regular($font-size-small)
  transition: all .3s ease 0s
  position: relative
  overflow: hidden
  box-sizing: border-box
  width: 100%
  height: auto
  padding: 10px 15px
  background-color: #FAFBFB
  border: 1px solid #E1E4E8
  border-radius: 3px
  color: #47474D
  line-height: 2
  text-overflow: ellipsis
  -webkit-appearance: none
  margin: 0 0 12px 0

  &::placeholder
    color: #8A94A6

  &::-ms-clear
    display: none

  &:focus
    outline: none
    border-color: #B6B8C1

  &[disabled]
    background-color: #FAFBFB
    border-color: #FAFBFB

.login-page__form
  width: 90%
  max-width: 376px
  box-sizing: border-box
  padding: 40px 0
  background-color: $white
  display: flex
  flex-direction: column

  @media #{$phone}
    padding: 60px 0 0 0
    min-width: 0

.login-page__label
  @include secondary-font-regular()
  color: $dark-text
  margin-top: 27px

.login-page__button
  margin-top: 32px
  width: 100%

.login-page__error-container
  color: #f64e3c
  font-size: $font-size-small

.login-page__link
  color: $lt-green
  font-size: 16px
  font-weight: 700
  font-family: $font-barlow
  text-decoration: underline

.login-page__forgot-password-link
  text-align: right

.login-p
  margin: 32px 0
  font-family: $font-barlow
  line-height: 24px

.login-page-line
  width: 100%
  height: 1px
  background-color: #e6e6e6
