@import "~style/vars.sass"
@import "~style/mixins.sass"

.worksite-selector__trigger
  @include primary-font-bold()
  font-size: 32px
  display: flex
  align-items: center

  @media #{$phone}
    font-size: 24px

.worksite-selector__caret
  @include hover-transition-simple()
  display: inline-block
  box-sizing: border-box
  width: 11px
  height: 6px
  background-image: url("../../images/hexagon-color.static.svg")
  margin-left: 8px

.worksite-selector__address
  @include secondary-font-bold()
  display: flex
  justify-content: flex-end
  flex-direction: column
  margin-left: 30px
  color: $color-text-300

.dropdown-panel--worksite
  .dropdown-panel__content
    position: absolute
    z-index: 5
    display: flex
    flex-direction: column
    background-color: white
    box-shadow: $shadow-level-3
    min-width: 300px

.dropdown-panel__worksite
  @include secondary-font-bold()

  cursor: pointer
  padding: 15px 10px
  text-transform: uppercase
  color: $color-text-300
  width: 100%
  
  &:not(:first-child)
    border-top: 1px solid $light-gray-2

  &:hover
    background-color: $light-gray-2