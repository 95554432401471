a
  text-decoration: none
  color: $black-text

b,
.txt-bold
  @include primary-font-bold(inherit)

.lt-h1
  @include primary-font-bold($font-size-title-xl)
  color: $color-text-300

.lt-h2
  @include primary-font-bold($font-size-title-large)
  color: $color-text-300

.lt-h3
  @include primary-font-bold($font-size-title-medium)
  color: $color-text-300

.lt-h4
  @include primary-font-bold($font-size-title-regular)
  color: $color-text-300

.lt-h5
  @include primary-font-bold($font-size-title-small)
  color: $color-text-300

.lt-h6
  @include primary-font-bold($font-size-title-micro)
  color: $color-text-300
  margin-bottom: $spacing-base

.lt-h6--narrow
  @media #{$narrow-phone}
    font-size: 16px

.lt-text-normal
  @include secondary-font-regular(inherit)

.lt-text-normal-empty-list
  @include secondary-font-regular(inherit)
  color: $color-text-300
  margin: 0px

.lt-text-normal-hyperlink
  @include secondary-font-regular(inherit)
  text-decoration: underline
  color: $lt-green
  margin: 0px

.lt-text-large
  font-size: $font-size-large

.lt-text-xl
  font-size: $font-size-xl

.lt-text-medium
  font-size: $font-size-medium

.lt-text-regular
  font-size: $font-size-regular

.lt-text-small
  font-size: $font-size-small

.lt-text-micro
  font-size: $font-size-micro

.lt-text-color-gray
  color: $color-text-150

.lt-text-center
  text-align: center

.text-capitalize
  text-transform: capitalize

.txt-error
  color: $lt-pink-red
  font-size: $font-size-small

.info-text
  @include secondary-font-bold($font-size-small)
  display: flex
  align-items: flex-start
  margin: 0
  &:before
    @include primary-font-bold($font-size-micro)
    content: "i"
    flex-shrink: 0
    width: 16px
    height: 16px
    margin-top: 2px
    margin-right: 10px
    background-color: $color-brand-primary-200
    border-radius: 50%
    color: $white
    text-align: center
    line-height: 16px
