@import "../../style/mixins.sass"
@import "../../style/vars.sass"

/** Custom checbox */
.checkbox
  &.checkbox--partially
    .checkmark
      &:after
        left: 7px
        top: 11px
        width: 12px
        height: 3px
        border: solid #f8fbfb
        border-width: 0 0 3px 0
        transform: rotate(0deg)

/** Customize the label (the container) */
.checkbox-container
  @include hover-transition-simple()
  display: block
  position: relative
  padding-left: 46px
  margin-bottom: 26px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  font-size: 17px
  color: $color-text-200

  &:hover
    color: $color-text-250

/* Hide the browser's default checkbox */
.checkbox-container input
  position: absolute
  opacity: 0

/* Create a custom checkbox */
.checkmark
  @include hover-transition-simple()
  box-sizing: border-box
  position: absolute
  top: 0
  left: 0
  height: 25px
  width: 25px
  background-color: $color-outline-50
  border: $color-outline-200 solid 1px
  border-radius: 3px

/* On mouse-over change background color */
.checkbox-container:hover input ~ .checkmark
  border-color: $color-outline-250

/* When the checkbox is checke... */
.checkbox-container input:checked
  & ~ .checkmark
    background-color: $color-brand-primary-200
    border: none

  & ~ span
    color: $color-brand-primary-200

.checkbox-container:hover input:checked
  & ~ .checkmark
    background-color: $color-brand-primary-250

  & ~ span
    color: $color-brand-primary-250

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after
  content: ""
  position: absolute
  display: none

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after
  display: block

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after
  left: 9px
  top: 7px
  width: 5px
  height: 10px
  border: solid $color-outline-50
  border-width: 0 3px 3px 0
  -webkit-transform: rotate(45deg)
  -ms-transform: rotate(45deg)
  transform: rotate(45deg)
