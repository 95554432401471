@import "./vars.sass"
@import "~style/mixins.sass"

@import "typography"
@import "forms"
@import "components"
@import "modal"
@import "misc"

body, html
  margin: 0
  padding: 0
  height: 100%
  display: flex
  flex-direction: column
  @include secondary-font-regular()

html
  box-sizing: border-box

*, *:before, *:after
  box-sizing: inherit


#root
  flex: 1
  display: flex
  flex-direction: column

.page-container
  flex-grow: 2
  flex-shrink: 0
  display: flex
  flex-direction: column

.page-container__content
  flex-grow: 2
  display: flex

  @media #{$tablet}
    flex-direction: column

  &.--with-top-banner
    padding-top: 0

.background-color--white
  background-color: $white
.background-color--gray
  background-color: $bg-gray
.background-color--light-gray
  background-color: $light-gray-2

.grid
  display: flex
  flex-wrap: wrap

.grid__item
  flex: 1 0 25%
  max-width: 25%

  &:hover
    @include hover-transition-simple
    opacity: .6

  &.grid__item--full
    flex: 1 0 100%
    max-width: 100%

  @media #{$phone}
    flex-basis: 50%
    max-width: 50%

    &:hover
      opacity: 1


.grid__item_33
  flex: 1 0 33%
  max-width: 33%

  &:hover
    @include hover-transition-simple
    opacity: .6

  &.grid__item--full
    flex: 1 0 100%
    max-width: 100%

  @media #{$phone}
    flex-basis: 50%
    max-width: 50%

    &:hover
      opacity: 1
