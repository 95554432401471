@import "~style/vars.sass"
@import "~style/mixins.sass"

.order-page__title
  cursor: pointer
  display: flex
  align-items: center

  &:after
    content: ""
    width: 0
    height: 0
    background-color: transparent
    border-left: 6px solid transparent
    border-right: 6px solid transparent
    border-top: 6px solid $color-text-300
    margin-left: 10px

  &:hover
    @include hover-transition-heading()

.grid__item_33
  position: relative

.jassikka-new-label
  position: absolute
  top: 15px
  right: 15px
  background-color: #003087
  color: white
  width: 56px
  height: 28px
  line-height: 28px
  text-align: center
  border-radius: 14px
  font-family: $font-barlow
  font-weight: bold