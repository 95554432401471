@import "~style/mixins.sass"
@import "~style/vars.sass"

.button
  @include hover-transition-simple()
  @include primary-font-bold()
  align-self: flex-start // The button is not expanded (aka. full-width/end-to-end)
  background-color: $color-brand-primary-200
  border-radius: 3px
  border-width: 0
  color: white
  cursor: pointer
  text-align: center
  padding: 1rem 1.5rem
  display: inline-block

  @media #{$phone}
    padding: 1rem

  &:hover
    background-color: $color-brand-primary-250
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.08)

  &:disabled
    background-color: $color-brand-primary-100
    box-shadow: none
    cursor: default

  &.button--expand
    width: 100%


  &.button--slim
    padding: 9px 35px

  &.button--gray-with-border
    @include secondary-font-bold()
    padding: 9px 35px
    border: 1px solid #C6CDD7
    background-color: #ffffff
    font-size: $font-size-regular
    color: #72737C

    &:disabled
      opacity: 0.6

  &.button--with-icon
    display: flex
    align-items: center
    padding: 0.5rem 1rem

  &.button--plus
    @include secondary-font-bold()

    min-width: 150px

  &.button--transparent-with-mobile
    @media #{$tablet}
      height: 32px
      width: 32px
      justify-content: center
      border-radius: 50%
      padding: 0

    >div:first-child
      display: flex
      margin-right: 8px

      img
        height: 16px
        width: 16px

      @media #{$tablet}
        margin-right: 0

        img
          height: 20px
          width: 20px

    >div:last-child
      font-size: 16px

      @media #{$tablet}
        display: none

  &.button--hover-zoom
    &:hover
      transform: scale(1.03)

  .spinner-container .fa-spin
    font-size: 15px
