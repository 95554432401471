@import "~style/vars.sass"
@import "~style/mixins.sass"

.search
  position: relative
  width: 100%
  max-width: 600px
  background-color: white

  .search__input
    box-sizing: border-box
    width: 100%
    height: 100%
    padding: 10px 10px 10px 40px
    background-color: transparent
    border: 1px solid $color-outline-200
    border-radius: 4px
    font-size: $font-size-small
    color: $color-text-200

    &:focus
      @include shadow-level-2()
      outline: 0

  .search__icon
    z-index: 2
    position: absolute
    top: 0
    left: 0
    width: 40px
    height: 100%
    line-height: 100%
    background-repeat: no-repeat
    background-position: center
    background-size: 18px
    pointer-events: none

  .search__icon--search
    background-image: url("../../images/search-dark.png")

  .search__icon--cancel
    background-image: url("../../images/close.svg")
    background-size: 13px
    pointer-events: auto
    cursor: pointer
