@import "~style/vars.sass"
@import "~style/mixins.sass"

.DayPickerInput
  width: 100%

  > input
    @include secondary-font-regular()
    @include input-field-base()
    width: 100%
    background-color: $color-outline-50

    &:focus
      outline: 0
      border-color: $color-outline-250

.margin-top-10
  margin-top: 10px

.info-text-not-bold
  font-size: 14px
  font-family: $font-barlow
  font-weight: 400
  margin: 0
  display: flex
  align-items: flex-start
  &:before
    @include primary-font-bold($font-size-micro)
    content: "i"
    flex-shrink: 0
    width: 14px
    height: 14px
    margin-top: 2px
    margin-right: 10px
    background-color: $color-brand-primary-200
    border-radius: 50%
    color: $white
    text-align: center
    line-height: 16px

.form-error
  margin: 0
  font-family: $font-barlow
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: #B31F1F

.no-bottom-margin
  margin-bottom: 0

.input-with-error-wrapper
  position: relative
  &:after
    display: block
    content: "!"
    width: 16px
    height: 16px
    background-color: #B31F1F
    border-radius: 50%
    color: $white
    text-align: center
    line-height: 16px
    right: 16px
    position: absolute
    top: 14px
  .input-with-error
    border: 2px solid #B31F1F