@import "~style/vars.sass"

.error-container.error-container--no-active-worksite
  flex-grow: 1
  flex-direction: column

  .error-container--no-active-worksite__contact-link
    white-space: nowrap
    text-align: center

  .error-container--no-active-worksite__heading
    text-align: center
  .error-container--no-active-worksite__guide
    text-align: center

  @media #{$tablet, $phone}
    padding: 10px

