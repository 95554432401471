.user-management-controls__container
  display: flex
  flex-direction: column
  align-items: flex-end
  row-gap: 20px

.users-table-controls__container
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-end
  width: 100%

.loading-spinner__container
  margin-top: 16px

.no-user-search-results-text__container
  margin-top: 32px
  text-align: center
