@import "~style/vars.sass"
@import "~style/mixins.sass"
@import "~style/vars.sass"

.orders-listing-order
  padding: $spacing-small
  background-color: $white
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.05)
  border-radius: 4px
  margin-bottom: $spacing-small
  max-width: 540px

.orders-listing-order__title
  @include secondary-font-bold()
  margin: 0 0 $spacing-small 0
  font-size: $font-size-regular
  letter-spacing: 0
  line-height: 17px
  text-transform: capitalize

.orders-listing-order__text
  text-transform: capitalize

.label-value-row
  display: flex
  flex-direction: row
  justify-content: space-between
  .order-label
    color: #6A6262
    font-size: 16px
    line-height: 18px
    margin-bottom: 8px
  .order-value
    color: #1A1A1A
    font-weight: 500
    font-size: 16px
    line-height: 18px

.order-status-wrapper
  margin-top: 8px
  display: flex
  gap: 7px
  align-items: center

.order-status-reason
  font-size: 16px
  line-height: 18px
  color: #6a6262
  font-weight: 400
  margin-top: 8px
