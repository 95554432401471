@import "~style/vars.sass"
@import "~style/mixins.sass"

.dropdown-panel
  position: relative

  &--is-open
     .dropdown-panel__content
        display: block

.dropdown-panel__content
  display: none