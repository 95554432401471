@use "sass:math"
@import "~style/mixins.sass"

.content-container
  flex-grow: 2

.content-container__inner
  padding-top: 24px
  padding-bottom: 96px
  margin: 0 20px

  @media #{$tablet}
    margin: 0 math.div(100 - $content-width, 2)

  @media #{$phone}
    width: 90%
    margin: 0 auto


  &--width-restrict
    width: $content-width
    max-width: $content-max-width
    padding-top: 24px
    padding-bottom: 96px
    margin: 0 auto

    @media #{$phone}
      width: 90%

  @media #{$phone}
    &--mobile-stretch
      .container__mobile-stretch-elem
        width: 110%
        margin-left: -5%