@import "~style/vars.sass"
@import "~style/mixins.sass"

.primary-header
  @include shadow-level-2()
  width: 100%
  height: $page-header-height-desktop
  background-color: $white
  border-bottom: 1px solid $color-outline-100
  z-index: 1000

  &.--with-top-banner
    position: relative

  @media #{$phone}
    height: $page-header-height-phone

.primary-header__container
  display: flex
  justify-content: space-between
  align-items: center
  width: $content-width
  max-width: $content-max-width
  margin: 0 auto

  @media #{$phone}
    flex-wrap: wrap
    justify-content: flex-start

.primary-header__left
  flex: 1
  display: flex
  align-items: center

  @media #{$phone}
    flex-basis: 70%
    order: 1

.primary-header__middle
  flex: 2
  display: flex
  justify-content: flex-start
  align-items: center
  padding-left: 3%

  @media #{$phone}
    order: 3
    flex-grow: 0
    flex-basis: 100%
    justify-content: center
    align-items: baseline
    padding-left: 0

.primary-header__right
  flex: 1
  display: flex
  align-items: center
  justify-content: flex-end

  @media #{$phone}
    order: 2
    flex-basis: 30%

.primary-header__logo
  width: 100%
  max-width: 210px
  margin: 10px 0

  img
    display: block



.primary-nav
  list-style: none
  display: flex
  padding-left: 0
  margin: 0

  @media #{$phone}
    flex-grow: 3
    justify-content: space-around

.primary-nav__item
  @include secondary-font-bold()
  @include hover-transition-simple()
  margin-right: 30px

  @media #{$phone}
    margin-right: 0
    font-size: $font-size-small

.primary-nav__link
  display: block
  padding: 20px 0
  color: $color-text-300
  border-bottom: 3px solid transparent

  &:hover,
  &:focus
    color: $color-brand-primary-200
    border-color: $color-brand-primary-200

  &--active
    color: $color-brand-primary-200
    border-color: $color-brand-primary-200

  @media #{$phone}
    padding: 10px 0

.primary-nav__link.primary-nav__link--active.primary-nav__link--frontpage span
  background-image: url("./images/icon-frontpage-green.svg")

.primary-nav__link.primary-nav__link--active.primary-nav__link--orders span
  background-image: url("./images/icon-order-green.svg")

.primary-nav__link.primary-nav__link--active.primary-nav__link--archive span
  background-image: url("./images/icon-archive-green.svg")

  @media #{$phone}
    border: none
    padding: 10px 0

.primary-nav__icon
  content: ''
  display: block
  width: 30px
  height: 30px
  margin: 0 auto

  &--frontpage
    background: url("./images/icon-frontpage.svg") no-repeat center
    .primary-nav__item--active &,
    .primary-nav__item:hover &
      background-image: url("./images/icon-frontpage-green.svg")

  &--orders
    background: url("./images/icon-order.svg") no-repeat center
    .primary-nav__item--active &,
    .primary-nav__item:hover &
      background-image: url("./images/icon-order-green.svg")

  &--archive
    background: url("./images/icon-archive.svg") no-repeat center
    .primary-nav__item--active &,
    .primary-nav__item:hover &
      background-image: url("./images/icon-archive-green.svg")



/* Burger Menu */
.dropdown-panel--burger
  &.dropdown-panel--is-open
    .dropdown-panel__trigger
      @extend .primary-nav__item--active

      .dropdown-panel__trigger-icon,
      &:hover .dropdown-panel__trigger-icon
        background-image: url("./images/icon-close-green.svg")

  .dropdown-panel__trigger
    @extend .primary-nav__item
    border: 0
    margin-right: 0
    color: $color-text-300
    text-align: center

    &:hover,
    &:focus
      .dropdown-panel__trigger-icon
        @include hover-transition-simple()
        background-image: url("./images/icon-menu-green.svg")

      .dropdown-panel__trigger-label
        color: $color-brand-primary-200

  .dropdown-panel__trigger-icon
    display: flex
    justify-content: center
    align-items: center
    width: 30px
    height: 30px
    margin: 0 auto
    background: url("./images/icon-menu.svg") no-repeat center

  .dropdown-panel__content
    position: absolute
    right: 0
    z-index: 1000
    width: 300px
    margin-top: 15px
    background-color: $almost-white-gray
    box-shadow: $shadow-level-3

    @media #{$phone}
      right: 50%
      transform: translateX(14px)

  .dropdown-panel__caret
    width: 0
    height: 0
    top: -9px
    right: 4px
    position: absolute
    background-color: transparent
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid $almost-white-gray



.nav-list
  margin: 0
  padding: 0
  list-style: none

.nav-list__item
  cursor: pointer
  display: flex
  align-items: center
  font-size: $font-size-regular
  border-top: 1px solid $light-gray-2

  &:hover
    background-color: $light-gray-2

  &--heading
    @include secondary-font-bold($font-size-micro)
    padding: 15px 10px
    text-transform: uppercase
    margin-left: 10px
    color: $color-text-300

    &:hover
      color: inherit
      background-color: inherit

.nav-list__link
  @include secondary-font-regular()
  width: 100%
  height: 100%
  display: flex
  align-items: center
  padding: 7px 10px
  text-decoration: none

  &:hover
    color: inherit

.nav-list__icon
  width: 36px
  height: 36px
  margin-right: 4px
  background-size: 70% auto
  background-position: center center
  background-repeat: no-repeat

  &--omalt
    background-image: url('./images/icon-omalt.svg')

  &--ynet
    background-image: url('./images/icon-ymparistonetti.svg')

  &--logout
    background-image: url('./images/icon-person.svg')
    background-size: 65%

  &--contact
    background-image: url('./images/icon-contact.svg')
    background-size: 65%
