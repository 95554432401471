@import "~style/vars.sass"

@mixin primary-font-regular($font-size: $font-size-regular)
  font-family: $font-lt-regular
  font-weight: normal
  font-size: $font-size

@mixin primary-font-bold($font-size: $font-size-regular)
  font-family: $font-lt-bold
  font-weight: bold
  font-size: $font-size

@mixin primary-font-medium()
  font-family: $font-open-sans

@mixin primary-font-light()

@mixin secondary-font-regular($font-size: $font-size-regular)
  font-family: $font-open-sans
  font-weight: normal
  font-size: $font-size

@mixin secondary-font-bold($font-size: $font-size-regular)
  font-family: $font-open-sans-bold
  font-weight: bold
  font-size: $font-size

@mixin hover-transition-simple()
  cursor: pointer
  transition: all .3s ease 0s

@mixin hover-transition-heading()
  cursor: pointer
  opacity: .8
  transition: all .3s ease 0s

@mixin input-field-base()
  box-sizing: border-box
  padding: 10px
  background-color: $white
  color: $color-text-300
  border: $color-outline-200 solid 0.9px
  border-radius: 3px
  box-shadow: none
  line-height: 1.2

@mixin shadow-level-1()
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05)

@mixin shadow-level-2()
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.08)

@mixin shadow-level-2-top-inset()
  box-shadow: inset 0 12px 9px -9px rgba(0, 0, 0, 0.08)

@mixin shadow-level-3()
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1)

@mixin shadow-level-4()
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08)

@mixin dropdown-notch()
  content: ''
  margin-left: 10px
  position: relative
  top: 15px
  left: 0
  width: 0
  height: 0
  border: 5px solid
  border-color: $color-text-300 transparent transparent transparent
