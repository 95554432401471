.user-form__container
  max-width: 400px
  margin: auto

.user-form__cancel-submit-buttons-container
  display: flex
  flex-direction: row

.spaced-button
  margin: 0 4px

.cancel-button
  background-color: white
  color: black

  &:hover
    background-color: #e0e0e0