
// Supported screen sizes. Usage `@media #{$phone} { .... }`
$narrow-phone:  "only screen and (max-width: 320px)"
$phone:         "only screen and (max-width: 823px)"
$tablet:        "only screen and (max-width: 1024px)"
$desktop:       "only screen and (max-width: 1199px)"

$color-brand-primary-50:  #c5fb96
$color-brand-primary-100: #a5e964
$color-brand-primary-150: #86cd31
$color-brand-primary-200: #64a60a
$color-brand-primary-250: #2d8d04
$color-brand-primary-300: #027401
$color-brand-primary-350: #005917

$color-brand-secondary-50:  #c7f8fb
$color-brand-secondary-100: #84e5eb
$color-brand-secondary-150: #42c6cf
$color-brand-secondary-200: #019ca6
$color-brand-secondary-250: #007a90
$color-brand-secondary-300: #005978
$color-brand-secondary-350: #003c5e

$color-primary-negative-50:  #ffc8c7
$color-primary-negative-100: #ff9285
$color-primary-negative-150: #ff6a58
$color-primary-negative-200: #f64e3c
$color-primary-negative-250: #d42c2b
$color-primary-negative-300: #aa1d2b
$color-primary-negative-350: #801228

$color-text-50:  #f8f8f8
$color-text-100: #cccbcf
$color-text-150: #9f9fa6
$color-text-200: #72737c
$color-text-250: #5c5d64
$color-text-300: #47474d
$color-text-350: #313235

$color-outline-50:  #f8fbfb
$color-outline-100: #eaf0f4
$color-outline-150: #dde4ea
$color-outline-200: #d2d8e0
$color-outline-250: #b6b8c1
$color-outline-300: #9a9aa4
$color-outline-350: #7f7e85

$color-accent-50:  #ffdbe8
$color-accent-100: #ff9abe
$color-accent-150: #ff689a
$color-accent-200: #f4457b
$color-accent-250: #d23364
$color-accent-300: #aa244e
$color-accent-350: #801739


$lt-brand-light: #ECF4E3
$lt-green: $color-brand-primary-200
$lt-light-green: #78be20
$lt-bright-green: #D0DF00
$lt-blue: #009ca6
$lt-bright-blue: #2DCCD3
$lt-turqoise-blue: #019CA6
$lt-dark-red: #AF272F
$lt-dark-red-2: #AA244E
$lt-red: #E04E39
$lt-pink: #F4457B
$lt-pink-red: #F64E3C
$lt-salmon-red: #FFC8C7
$lt-brown: #7A6855
$lt-beige: #B7B09C
$lt-bright-blue-10: #E8F9FA
$lt-yellow: #FFD100
$white: #ffffff

$bg-gray: #f3f2f0

$grey-line: #e0e0e0
$lt-grey-line: #E9EFF4
$beige-line: #D1CBB8
$background: #F2F2F2
$grey-text: #7d7d7d
$grey-text-2: #707070
$light-gray: #9b9b9b
$light-gray-2: #f5f5f5
$light-gray-3: #989898
$light-gray-divide: #C6CDD7
$black-text: #3f3f3f
$dark-black-text: #27272B
$dark-text: #303030
$soft-dark-text: #454545
$almost-white-gray: #fbfbfb
$gainsboro-gray: #D8D8D8
$alice-blue: #f0f8ff
$disabled-gray: #dce0e0
$black: #1a1a1a


$shadow-level-1: 0 0 5px 0 rgba(0, 0, 0, 0.05)
$shadow-level-2: 0 0 9px 0 rgba(0, 0, 0, 0.08)
$shadow-level-3: 0 0 16px 0 rgba(0, 0, 0, 0.1)
$shadow-level-4: 0 0 24px 0 rgba(0, 0, 0, 0.1)


@font-face
  font-family: 'LT Trim Regular'
  src: url('~fonts/LTTrimWeb-Regular.woff') format('woff')

@font-face
  font-family: 'LT Trim Bold'
  src: url('~fonts/LTTrimWeb-Bold.woff') format('woff')
  font-weight: bold

@font-face
  font-family: 'Open Sans Regular'
  src: url('~fonts/OpenSans-Regular.ttf') format('truetype')

@font-face
  font-family: 'Open Sans Bold'
  src: url('~fonts/OpenSans-Bold.ttf') format('truetype')

@font-face
  font-family: "Barlow"
  src: url("~fonts/Barlow-Regular-webfont.eot")
  src: url("~fonts/Barlow-Regular-webfont.eot?#iefix") format("embedded-opentype"),
  src: url("~fonts/Barlow-Regular-webfont.woff") format("woff"),
  src: url("~fonts/Barlow-Regular-webfont.ttf") format("truetype"),
  src: url("~fonts/Barlow-Regular-webfont.svg#barlowregular") format("svg")
  font-weight: 400
  font-style: normal

@font-face
  font-family: "Barlow"
  src: url("~fonts/Barlow-SemiBold-webfont.eot")
  src: url("~fonts/Barlow-SemiBold-webfont.eot?#iefix") format("embedded-opentype"),
  src: url("~fonts/Barlow-SemiBold-webfont.woff") format("woff"),
  src: url("~fonts/Barlow-SemiBold-webfont.ttf") format("truetype"),
  src: url("~fonts/Barlow-SemiBold-webfont.svg#barlowsemibold") format("svg")
  font-weight: 600
  font-style: normal

@font-face
  font-family: "Barlow"
  src: url("~fonts/Barlow-Bold-webfont.eot")
  src: url("~fonts/Barlow-Bold-webfont.eot?#iefix") format("embedded-opentype"),
  src: url("~fonts/Barlow-Bold-webfont.woff") format("woff"),
  src: url("~fonts/Barlow-Bold-webfont.ttf") format("truetype"),
  src: url("~fonts/Barlow-Bold-webfont.svg#barlowbold") format("svg")
  font-weight: 700
  font-style: normal

$font-barlow: "Barlow", sans-serif
$font-lt-regular: 'LT Trim Regular', sans-serif
$font-lt-bold: 'LT Trim Bold', sans-serif
$font-open-sans: 'Open Sans Regular', sans-serif
$font-open-sans-bold: 'Open Sans Bold', sans-serif

$font-size-title-xl: 56px
$font-size-title-large: 44px
$font-size-title-medium: 32px
$font-size-title-regular: 32px
$font-size-title-small: 24px
$font-size-title-micro: 20px

$font-size-xl: 19px
$font-size-large: 18px
$font-size-medium: 17px
$font-size-regular: 16px
$font-size-small: 14px
$font-size-micro: 12px

$spacing-small: 1rem
$spacing-base: 1.5rem
$spacing-large: 3rem
$spacing-xlarge: 4rem

$content-width: 90%
$content-max-width: 1200px
$page-header-height-desktop: 66px
$page-header-height-phone: 120px
