.flash
  position: fixed
  top: 0
  width: 100%
  z-index: 1000

.flash .flash-error
  text-align: center
  padding-top: 10px
  padding-bottom: 10px
  color: white
  display: block
  margin: auto
  background-color: #e24d32

.flash .flash-info
  padding-top: 10px
  padding-bottom: 10px
  color: white
  display: block
  margin: auto
  text-align: center
  background-color: green

.flash .flash-hidden
  display: none