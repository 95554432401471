@import "~style/vars.sass"
@import "~style/mixins.sass"
  
.contact-form
  color: $color-text-300

.contact-form__container
  padding: 24px
  background-color: white
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1)

.contact-form__info
  @include secondary-font-regular()

  margin-bottom: 16px

.contact-form__sub-title
  @include primary-font-regular($font-size-large)

.contact-form__text
  margin-top: 8px
  margin-bottom: 24px

.contact-form--success
  display: flex
  flex-direction: column
  align-items: center
